import React, { useRef, useEffect } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./First.module.scss";
import gsap, { Power0, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import man from "../../../assets/img/services/1/people.png"

gsap.registerPlugin(ScrollTrigger);
const First = (props) => {
  const firstRef = useRef(null);
  const titleWrapRef = useRef(null);
  const carRef = useRef(null);
  const carRef1 = useRef(null);
  const moonRef = useRef(null);
  const rockBotRef1 = useRef(null);
  const rockBotRef11 = useRef(null);
  const rockBotRef2 = useRef(null);
  const rockBotRef22 = useRef(null);
  const rockBotRef3 = useRef(null);
  const rockBotRef33 = useRef(null);
  const rockBotRef4 = useRef(null);
  const rockBotRef44 = useRef(null);
  const rockTopRef1 = useRef(null);
  const rockTopRef2 = useRef(null);
  const rockTopRef3 = useRef(null);
  const rockTopRef4 = useRef(null);
  const rockTopRef11 = useRef(null);
  const rockTopRef22 = useRef(null);
  const rockTopRef33 = useRef(null);
  const rockTopRef44 = useRef(null);
  const sonneRef = useRef(null);
  const sonneRef1 = useRef(null);
  const peopleRef = useRef(null);
  const peopleRef1 = useRef(null);

  useEffect(() => {
    gsap.to(titleWrapRef.current, {
      y: 0,
      opacity: 1,
      delay: 1,
      ease: Power0.easeNone,
      duration: 2.5,
    });
    if (document.documentElement.clientWidth > 1220) {
      gsap.to(sonneRef.current, {
        y: 0,
        delay: 1,
        duration: 2.5,
        ease: Power0.easeNone,
      });

      gsap.timeline().to(sonneRef1.current, {
        y: 100,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top",
          end: "bottom -300px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(rockBotRef11.current, {
        x: -100,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top",
          end: "bottom -300px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(rockBotRef22.current, {
        x: -100,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top",
          end: "bottom -300px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(rockBotRef33.current, {
        x: 100,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top",
          end: "bottom -300px",
          scrub: 5,
          id: "scrub",
        },
      });

      gsap.timeline().to(rockBotRef44.current, {
        x: 100,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top",
          end: "bottom -300px",
          scrub: 5,
          id: "scrub",
        },
      });

      gsap.timeline().to(peopleRef1.current, {
        x: -200,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top",
          end: "bottom -300px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(carRef1.current, {
        x: -200,
        y: -100,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top",
          end: "bottom -100px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(rockTopRef11.current, {
        x: -50,
        y: -50,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top",
          end: "bottom -100px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(rockTopRef22.current, {
        x: 50,
        y: -50,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top",
          end: "bottom -100px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(rockTopRef33.current, {
        x: -50,
        y: -150,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top",
          end: "bottom -100px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(rockTopRef44.current, {
        x: 50,
        y: -150,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top",
          end: "bottom -100px",
          scrub: 5,
          id: "scrub",
        },
      });

      gsap.to(peopleRef.current, {
        y: 0,
        x: 0,
        scale: 1,
        delay: 1,
        duration: 2.5,
        ease: Power0.easeNone,
      });
      gsap.to(carRef.current, {
        y: 0,
        x: 0,
        delay: 1,
        ease: Power0.easeNone,
        duration: 2.5,
      });
      gsap.to(moonRef.current, {
        y: 0,
        x: 0,
        rotate: 0,
        delay: 1,
        ease: Power0.easeNone,
        duration: 2.5,
      });
      gsap.to(rockBotRef1.current, {
        y: 0,
        x: 0,
        delay: 1,
        ease: Power0.easeNone,
        duration: 2.5,
      });
      gsap.to(rockBotRef2.current, {
        y: 0,
        x: 0,
        delay: 1,
        ease: Power0.easeNone,
        duration: 2.5,
      });
      gsap.to(rockBotRef3.current, {
        y: 0,
        x: 0,
        delay: 1,
        ease: Power0.easeNone,
        duration: 2.5,
      });
      gsap.to(rockBotRef4.current, {
        y: 0,
        x: 0,
        delay: 1,
        ease: Power0.easeNone,
        duration: 2.5,
      });
      gsap.to(rockTopRef1.current, {
        y: 0,
        x: 0,
        delay: 1,
        ease: Power0.easeNone,
        duration: 2.5,
      });
      gsap.to(rockTopRef2.current, {
        y: 0,
        x: 0,
        delay: 1,
        ease: Power0.easeNone,
        duration: 2.5,
      });
      gsap.to(rockTopRef3.current, {
        y: 0,
        x: 0,
        delay: 1,
        ease: Power0.easeNone,
        duration: 2.5,
      });
      gsap.to(rockTopRef4.current, {
        y: 0,
        x: 0,
        delay: 1,
        ease: Power0.easeNone,
        duration: 2.5,
      });
    }
  }, []);

  return (
    <div>
      <div className={classes.first}>
        <div className={classes.firstBody}>
          <div className={classes.firstBgMain}></div>
          <div className={classes.firstBgBot}></div>

          <div className={classes.firstBgSonne} ref={sonneRef}>
            <div ref={sonneRef1}></div>
          </div>

          <div className={classes.firstBgMoon} ref={moonRef}>
            <div></div>
          </div>
          <div className={classes.firstBodyWrapperImg}>
            <div className={classes.firstBgRockBottom2} ref={rockBotRef2}>
              <div ref={rockBotRef22}></div>
            </div>
            <div className={classes.firstBgRockBottom4} ref={rockBotRef4}>
              <div ref={rockBotRef44}></div>
            </div>
          </div>

          <div className={["container", classes.cont].join(" ")}>
            <div className={classes.firstWrapper}>
              <div className={classes.firstBgRockBottom1} ref={rockBotRef1}>
                <div ref={rockBotRef11}></div>
              </div>

              <div className={classes.firstBgRockBottom3} ref={rockBotRef3}>
                <div ref={rockBotRef33}></div>
              </div>

              <div className={classes.firstBgPeople} ref={peopleRef}>
                <div ref={peopleRef1}></div>
              </div>
              <div className={classes.firstBgCar} ref={carRef}>
                <div ref={carRef1}></div>
              </div>
              <div className={classes.firstBgRockTop1} ref={rockTopRef1}>
                <div ref={rockTopRef11}></div>
              </div>
              <div className={classes.firstBgRockTop2} ref={rockTopRef2}>
                <div ref={rockTopRef22}></div>
              </div>
              <div className={classes.firstBgRockTop3} ref={rockTopRef3}>
                <div ref={rockTopRef33}></div>
              </div>
              <div className={classes.firstBgRockTop4} ref={rockTopRef4}>
                <div ref={rockTopRef44}></div>
              </div>
              <div className={classes.firstTitleWrap}>
                <div className={classes.firstTitleWrap1} ref={titleWrapRef}>
                  <div className={classes.firstTitle}>
                  Software Development Space
                  </div>
                  <div className={classes.firstSubtitle}>
                  From the Artistry of<b style={{color: '#F8D448'}}> Design</b> <br/><b > to </b><br/>the <b>Brilliance </b>of <b style={{color: '#F8D448'}}>Full-Stack</b> App Launch</div>
                  <div className={classes.firstBtn}>
                  <img className={classes.manBanner} alt="" src={man}/>

                    <Button
                    
                      onClick={() => {
                        props.setPopupVisible(true);
                        props.serFormName(props.formName);
                      }}
                    >

                      Get Estimations
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
