import React, { useRef, useEffect } from "react";
import classes from "./Three.module.scss";
import gsap, { Power0, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { renderHook } from "@testing-library/react";
gsap.registerPlugin(ScrollTrigger);
const Three = () => {
  const arrowRef = useRef(null);
  const threeRef = useRef(null);
  const leftCol1 = useRef(null);
  const leftCol2 = useRef(null);
  const leftCol3 = useRef(null);
  const leftCol4 = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().to(arrowRef.current, {
        y: 0,
        scrollTrigger: {
          trigger: threeRef.current,
          start: "top bottom",
          end: "top top+=200px",
          scrub: 10,
          id: "scrub1",
          scrub: true,
        },
      });
      gsap.timeline().to(leftCol1.current, {
        x: 0,
        scrollTrigger: {
          trigger: threeRef.current,
          start: "top bottom",
          end: "top top+=200px",
          scrub: 10,
          scrub: true,
          id: "scrub1",
        },
      });
      gsap.timeline().to(leftCol2.current, {
        x: 0,
        scrollTrigger: {
          trigger: threeRef.current,
          start: "top bottom",
          end: "top top+=200px",
          scrub: 10,
          id: "scrub1",
          scrub: true,
        },
      });
      gsap.timeline().to(leftCol3.current, {
        x: 0,
        scrollTrigger: {
          trigger: threeRef.current,
          start: "top bottom",
          end: "top top+=200px",
          scrub: 10,
          id: "scrub1",
          scrub: true,
        },
      });
      gsap.timeline().to(leftCol4.current, {
        x: 0,
        scrollTrigger: {
          trigger: threeRef.current,
          start: "top bottom",
          end: "top top+=200px",
          scrub: 10,
          scrub: true,
          id: "scrub1",
        },
      });
    }
  }, []);

  return (
    <div className={classes.three}>
      <div className={classes.threeBody}>
        <div className="container">
          <div className={[classes.threeTitlte, "title"].join(" ")}>
          Elevate Your Digital Presence with Our Software Solutions
          </div>
          <div className={classes.threeSubtitlte}>
          Discover the potential of your digital presence with our software solutions. Let's collaborate to take your business to new heights. Contact us today to start your journey towards digital excellence.
          </div>
          <div className={classes.threeWrapper} ref={threeRef}>
            <div className={classes.threeRow1} ref={leftCol1}>
              <div className={classes.threeRowContent}>
                <div className={classes.threeRowContentText}>
                Website Development: From eye-catching landing pages to robust e-commerce platforms, we design and develop websites that not only look great but also perform exceptionally well.
                </div>
              </div>
            </div>
            <div className={classes.threeRow2} ref={leftCol2}>
              <div className={classes.threeRowContent}>
                <div className={classes.threeRowContentText}>
                Mobile App Development: Reach your customers wherever they are with custom mobile apps for iOS and Android. We'll turn your app ideas into reality, offering a seamless user experience.
                </div>
              </div>
            </div>
            <div className={classes.threeRow3} ref={leftCol3}>
              <div className={classes.threeRowContent}>
                <div className={classes.threeRowContentText}>
                Web Application Development: Need a dynamic web app? We build feature-rich web applications tailored to your business requirements, ensuring scalability and security.                </div>
              </div>
            </div>
            <div className={classes.threeRow4} ref={leftCol4}>
              <div className={classes.threeRowContent}>
                <div className={classes.threeRowContentText}>
                Consultation and Support: Our experts are here to guide you at every step, from project ideation to post-launch support. We're committed to your success.                </div>
              </div>
            </div>
            <div className={classes.threeWrapperCenter} ref={arrowRef}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
