import React from 'react';
import { useState, useRef, createRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form, Field } from 'react-final-form';
import { initialValues } from './initial-values';
import classes from './Form.module.scss';
import 'react-phone-input-2/lib/style.css';
import { validate } from '../../WebDevelopment/ContactForm/schema-validate';

const FormComponent = ({
  smartForm = true,
  setH = () => {},
  nameForm = 'Isn’t specified',
  backInput = false,
  title = ''
}) => {
  const [hideForm, setHideForm] = useState(false);
  const [isCaptchaLoaded, setCaptchaLoaded] = useState(false);
  const [isSent, setIsSent] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isVerifyCaptcha, setIsVerifyCaptcha] = useState('');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const buyer = urlParams.get('buyer');
  const buyerRes = buyer === null || buyer === '' ? 'Isn’t specified' : buyer;
  const source = urlParams.get('source');
  const sourceRes = source === null || source === '' ? 'Isn’t specified' : source;
  const formRef = useRef(null);
  const recaptchaRef = createRef();

  useEffect(() => {
    const handleScroll = () => {
      const formElement = formRef.current;

      if (formElement) {
        const rect = formElement.getBoundingClientRect();
        const isVisible =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth);

        if (isVisible && !isCaptchaLoaded) {
          setCaptchaLoaded(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isCaptchaLoaded]);

  console.log(isCaptchaLoaded);
  const sendEmail = async (values, formElement) => {
    return await emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      formElement,
      process.env.REACT_APP_PUBLIC_KEY
    );
  };
  const handleSuccess = (form) => {
    setIsSent('Sent');
    form.restart();
    setIsVerifyCaptcha('');
    recaptchaRef.current.reset();
  };

  const handleFailure = () => {
    setIsSent('Wrong');
  };
  const onSubmit = async (values, form) => {
    const formElement = formRef.current;
    if (captchaValue) {
      try {
        const response = await sendEmail(values, formElement);
        if (response?.status === 200) {
          handleSuccess(form);
        } else {
          handleFailure();
        }
      } catch (error) {
        handleFailure();
      }
    } else {
      setIsVerifyCaptcha('Captcha failed');
    }
  };

  return (
    <div className="container">
      <div className={`${classes.form} ${!smartForm ? classes.bigForm : undefined}`}>
        {!smartForm && (
          <div>
            <div className={classes.bigTitle}>
              Are you interested work with us or want to get our expertise?
              <br />
              leave your contacts here{' '}
            </div>
            <div className={classes.bigText}>Leave your contact details here:</div>
          </div>
        )}
        <div className={classes.content}>
          <div
            className={
              hideForm ? [classes.contentArrow, classes.visible].join(' ') : classes.contentArrow
            }
          ></div>
          <div
            className={
              hideForm ? [classes.contentWrap, classes.hide].join(' ') : classes.contentWrap
            }
          >
            {smartForm && (
              <div>
                <div className={classes.title} dangerouslySetInnerHTML={{ __html: title }}></div>
                <div className={classes.text}>Please Leave your contact details here:</div>
              </div>
            )}
            {isSent === 'Sent' && (
              <div className={classes.sentStatusSuccess}>
                <p>
                  Your contact information has been successfully sent.
                  <br /> We will contact you shortly.
                </p>
              </div>
            )}
            {isSent === 'Wrong' && (
              <div className={classes.sentStatusError}>
                <p>Failed to send contact information, please try again!</p>
              </div>
            )}
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              validate={validate}
              render={({ handleSubmit, form, submitting, pristine, values, errors, touched }) => (
                <form ref={formRef} onSubmit={handleSubmit}>
                  <div>
                    {touched?.name && errors?.name && (
                      <div style={{ color: 'red', marginBottom: '5px' }}>{errors?.name}</div>
                    )}
                    <Field name="name">
                      {({ input }) => (
                        <input
                          {...input}
                          type="text"
                          placeholder="Name"
                          style={{
                            borderColor: touched?.name && errors?.name ? 'red' : 'initial'
                          }}
                        />
                      )}
                    </Field>
                    {touched?.email && errors?.email && (
                      <div style={{ color: 'red', marginBottom: '5px' }}>{errors?.email}</div>
                    )}
                    <Field name="email">
                      {({ input }) => (
                        <input
                          {...input}
                          type="text"
                          placeholder="Email"
                          style={{
                            borderColor: touched?.email && errors?.email ? 'red' : 'initial'
                          }}
                        />
                      )}
                    </Field>
                    {touched?.phone && errors?.phone && (
                      <div style={{ color: 'red', marginBottom: '5px' }}>{errors?.phone}</div>
                    )}
                    <Field name="phone">
                      {({ input }) => <input {...input} type="text" placeholder="Phone" />}
                    </Field>
                  </div>
                  {isVerifyCaptcha && (
                    <div style={{ color: 'red', marginBottom: '5px' }}>{isVerifyCaptcha}</div>
                  )}
                  <div className={classes.reCaptcha}>
                    {isCaptchaLoaded && (
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        size="normal"
                        onChange={(value) => {
                          setCaptchaValue(value);
                        }}
                      />
                    )}
                  </div>
                  <div
                    className={classes.btnWrap}
                    onMouseEnter={() => {
                      setH(true);
                    }}
                    onMouseLeave={() => {
                      setH(false);
                    }}
                  >
                    <div
                      className={[classes.btn, classes.tildaripple].join(' ')}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      <div className={classes.ripple_effects}></div>
                      Submit
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormComponent;
