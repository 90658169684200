import React, { useState, useRef, useEffect } from "react";
import classes from "./OurApproach.module.scss"
import Rocket from "../../../assets/img/webDevelopment/rocket-img.png"
import RocketTablet from "../../../assets/img/webDevelopment/rocket-tablet-img.png"


const OurApproach = () => {
    const [tabs, setTabs] = useState([
        {
          id: 1,
          title: "Collection And Analysis Of Information",
          text: "<p>A proper approach to developing websites, applications and improving the web project as a whole relies on a preliminary analysis and collection of important information, such as the target audience, product purpose and potential competitors.</p><p>With this data, we form the foundation that determines future steps and stages of development.</p>",
          active: true,
        },
        {
          id: 2,
          title: "Technical Description, Development And Prototype Creation",
          text: "<p>Based on the collected information, technical documentation for the project is created for each page.</p><p>This document describes the project's requirements: its features, the technologies on which the project will be built, milestones, timeframes, and the technology stack (programming language, platforms, CMS) that will be used.</p><p>Layouts provide a schematic visual representation of each page of a future web project. Layouts do not contain graphic design elements such as graphics, colour schemes, logos, etc.</p><p>It roughly shows how the information will be organised on different pages, what content elements these sections will consist of, and how it will develop.</p><p>If you'd like, you can submit your hand-drawn layouts to help us better visualise the future of your web project.</p>",
          active: false,
        },
        {
          id: 3,
          title: "Web Design Developmen",
          text: "<p>The main goal of web design is to visualise the structure and functionality of your future project.</p><p>At this stage of development, all the information collected in the first stage of the analysis is implemented.</p><p>Our designers use your chosen graphics, fonts, buttons, images, logos and colours to design each site page.</p><p>The design will meet the needs of your target audience in a unique style. When the design project is ready, we will send it to you for review.</p><p>Our goal is 100% result.</p>",
          active: false,
        },
        {
          id: 4,
          title: "Front-End And Back-End Development",
          text: "<p>Front-end developers turn all the page layouts created in the previous step into real, dynamic, interactive web pages.</p><p>Our back-end developers maintain the functionality of each element. This step covers basic search engine optimisation to help your customers find your site in Internet search engines.</p><p>We customise meta tags, microdata, titles, robots.txt, sitemap file, images and keywords of your web pages to maximise your site's position in search results.</p><p>Then our QA engineers will check the site for errors, and the developers will fix the inconsistencies.</p>",
          active: false,
        },
        {
          id: 5,
          title: "Testing, Fixing And Launching",
          text: "<p>Our QA engineers are involved in every stage of website development, so only final testing is required at this stage. This strategy greatly speeds up the launch of your project.</p>Every page, form, button and link is checked.<p>Our QA engineers test the display of the site in different browsers, on different devices and on screen resolutions to ensure that the generated code fully complies with modern web standards and rules.</p><p>After the project has passed the final review and all errors have been fixed, it can be downloaded.</p><p>Once the project is up and running, we will run another round of testing to ensure that no unexpected errors occurred during the download and that all files are intact.</p>",
          active: false,
        },
      ]);

      const switchTabs = (e, id) => {
/*         var minHeight = 0
        minHeight = e.target.nextSibling.querySelector('.textWrap').clientHeight
        document.querySelector('.dynamicalHeight').style.minHeight = `${minHeight + 100}px` 
        console.log(document.querySelector('.dynamicalHeight').clientHeight)*/
        setTabs(
            tabs.map((item) => {
                if(item.id === id) {
                    return { ...item, active: true}
                } else {
                    return { ...item, active: false };
                }
            })
        );
      }
    return (
        <div className={classes.ourApproachContainer}>
            <h3 className={classes.ourApproachTitle}>Our Approach to Web Development <br /> of Your Project</h3>
            <div className="container">
                    <div className={classes.ourApproachImgTabletWrap}>
                        <img src={RocketTablet} alt="Rocket" />
                    </div>
                <div className={[classes.ourApproachWrap, 'dynamicalHeight'].join(" ")}>
                    <div className={classes.ourApproachImgWrap}>
                        <img src={Rocket} alt="Rocket" />
                    </div>
                    <div className={classes.ourApproachTabsWrap}>
                        {tabs.map((i) => {
                            return (
                                <div
                                key={i.id}
                                className={
                                    i.active
                                    ? [classes.sixRowLeftTab, classes.active].join(" ")
                                    : classes.sixRowLeftTab
                                }
                                >
                                    <div
                                        className={classes.sixRowLeftTabTitle}
                                        onClick={(e) => switchTabs(e, i.id)}
                                    >
                                        {i.id}
                                        </div>
                                    <div className={classes.sixRowLeftTabText}>
                                        <h6 className={classes.sixRowTitle}>{i.title}</h6>
                                        <div className="textWrap" dangerouslySetInnerHTML={{__html: i.text}} ></div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurApproach;