import React from "react";
import classes from "./WhatIsWebDevelopment.module.scss"


const WhatIsWebDevelopment = () => {
    return (
        <div className={classes.whatIsWebDevelopmentContainer}>
            <div className="container">
                <h3 className={classes.whatIsWebDevelopmentTitle}>What Is Web Development For?</h3>
                <div className={classes.whatIsWebDevelopmentWrap}>
                    <p>The first step is to Web development is a necessary step to growing your business. We live in a time when the Internet and modern technologies have penetrated every home, relegating offline sales strategies and customer acquisition to the background. </p>
                    <p>Whether you want to stand out in a competitive niche, take your business to the next level, reach a wider audience, or expand your contacts, you must have a website.</p>
                </div>
            </div>
        </div>
    )
}

export default WhatIsWebDevelopment;