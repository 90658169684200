import React from "react";
import classes from './OurTop.module.scss';
import card_01 from '../../../assets/img/MobileDevelopment/OurTop/card_01.png';
import card_02 from '../../../assets/img/MobileDevelopment/OurTop/card_02.png';
import card_03 from '../../../assets/img/MobileDevelopment/OurTop/card_03.png';

const cards = [
    {
        id: 1,
        image: card_01,
        title: 'Vladislav',
        text: 'Vladislav is an experienced mobile app developer specializing in React Native and Flutter. With a strong background in cross-platform development, he\'s passionate about creating innovative solutions for both iOS and Android. Vladislav excels in turning complex ideas into intuitive, user-friendly mobile apps. His dedication to staying up-to-date with the latest trends in React Native and Flutter ensures your app will always be on the cutting edge.',
    },
    {
        id: 2,
        image: card_02,
        title: 'Sergiy',
        text: 'Sergiy is a highly skilled mobile app developer, specializing exclusively in React Native (RN). With a wealth of experience in cross-platform app development, he\'s your go-to expert for creating powerful and efficient RN applications. Sergiy\'s meticulous attention to detail and dedication to the RN framework ensure that your app will not only meet but exceed your expectations. He thrives on delivering high-performance, user-friendly solutions for both iOS and Android platforms.',
    },
    {
        id: 3,
        image: card_03,
        title: 'Andrew',
        text: 'Andrew is a true maestro when it comes to React Native (RN) app development. With a deep and comprehensive understanding of the RN framework, he excels in crafting seamless and visually appealing applications. Andriy\'s commitment to perfection guarantees that your RN app will not only function flawlessly but also delight users with its intuitive design and exceptional performance. With Andriy on your team, you can trust that your RN app will stand out in app stores and leave a lasting impression on users.',
    },
];

const OurTop = () => {
    return (
        <section className="container">
            <div className={classes.ourTop}>
                <h2 className={[classes.title, 'font-38'].join(' ')}>
                    Our Top Mobile Developers
                </h2>
                <div className={classes.cards}>
                    {cards.map((card) =>
                        <div className={classes.card} key={card.id}>
                            <img className={[classes.cardImage, 'no-select'].join(' ')} src={card.image} alt=''/>
                            <h3 className={[classes.cardBottom, 'font-20'].join(' ')}>
                                {card.title}
                            </h3>
                            <div className={classes.cardShadow}></div>
                            <div className={classes.cardContent}>
                                <h4 className={[classes.cardTitle, 'font-20'].join(' ')}>
                                    {card.title}
                                </h4>
                                <p className={classes.cardText}>
                                    {card.text}
                                </p>
                            </div>
                        </div>                    
                    )}
                </div>
            </div>
        </section>
    );
};

export default OurTop;