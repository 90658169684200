import AboutPage from '../pages/AboutPage/AboutPage';
import BrandingPage from '../pages/BrandingPage/BrandingPage';
import ContactPage from '../pages/ContactPage/ContactPage';
import MainPage from '../pages/MainPage/MainPage';
import WebDevelopment from '../pages/WebDevelopment/WebDevelopment';
import MobileDevelopment from '../pages/MobileDevelopment/MobileDevelopment';
import DigitalMarketing from '../pages/DigitalMarketing/DigitalMarketing';
import Services from '../pages/Services/Services';

export const routes = [
  { path: '/', element: MainPage },
  { path: '/about', element: AboutPage },
  { path: '/contacts', element: ContactPage },
  { path: '/web-development', element: WebDevelopment },
  { path: '/mobile-development', element: MobileDevelopment },
  { path: '/services', element: Services },
  { path: '/designer', element: BrandingPage },
  //{ path: '/designer', element: DigitalMarketing }
];

export const headerRoutes = [
  { id: 1, path: '/start', text: 'Start' },
  { id: 2, path: '/about', text: 'About' },
  { id: 3, path: '/web-development', text: 'Web Development' },
  { id: 4, path: '/mobile-development', text: 'Mobile Development' },
  { id: 5, path: '/services', text: 'Services & Our Works' }
];
