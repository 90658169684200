import React from "react";
import classes from './Banner.module.scss';
import Header from '../../GlobalComponents/Header/Header';
import astronaut from '../../../assets/img/MobileDevelopment/Banner/astronaut.png';
import plane from '../../../assets/img/MobileDevelopment/Banner/plane.png';
import Button from "../../GlobalComponents/Button/Button";


const Banner = ({ setPopupVisible, serFormName, formName }) => {
    return (
        <div className={classes.banner}>
            <Header 
                setPopupVisible={setPopupVisible}
                serFormName={serFormName}
                formName={formName}
            />
            <div className="container">
                <div className={[classes.title, 'font-38'].join(' ')}>
                Unlocking Mobile App Innovation</div>
                <div className={[classes.text, 'font-20'].join(' ')}>
                Step into the realm of mobile app <b style={{color: 'yellow'}}>innovation</b>, where creativity knows no bounds. We're your partner in turning imaginative concepts into tangible mobile solutions.                </div>
                <Button
                    onClick={() => {
                        setPopupVisible(true);
                        serFormName(formName);
                    }}
                >
                    Place an order
                </Button>
            </div>
            <img className={[classes.plane, 'no-select'].join(' ')} src={plane} alt=''/>
            <img className={[classes.astronaut, 'no-select'].join(' ')} src={astronaut} alt=''/>
        </div>
    );
};

export default Banner;
