import React from "react";
import classes from "./Ready.module.scss";
import img1 from "../../../assets/img/services/1/2.svg";
import img2 from "../../../assets/img/services/1/1.svg";
import web from "../../../assets/img/services/1/web.png";
import img3 from "../../../assets/img/services/1/3.svg";
import mobile from '../../../assets/img/MobileDevelopment/how_we_create.png';

const Ready = () => {
  return (
    <div className={classes.ready}>
      <div className={classes.readyBody}>
        <div className="container">
          <div className={[classes.readyTitle, "font-38"].join(" ")}>
            We are Ready to Present Our Services!
          </div>
          <div className={[classes.readySubtitle, "font-20"].join(" ")}>
            Build Your Own App and get the most out of your business
          </div>
          <div className={classes.readyRow}>
            <div className={classes.readyRowColumn}>
              <div className={classes.readyRowColumnImg}>
                <img src={web} alt="" height={80}/>
              </div>
              <div
                className={[classes.readyRowColumnTitle, "font-20"].join(" ")}
              >
                Web development
              </div>
            </div>
            <div className={classes.readyRowColumn}>
              <div className={classes.readyRowColumnImg}>
                <img src={mobile} alt=""  height={100}/>
              </div>
              <div
                className={[classes.readyRowColumnTitle, "font-20"].join(" ")}
              >
                Mobile development
              </div>
            </div>
            <div className={classes.readyRowColumn}>
              <div className={classes.readyRowColumnImg}>
                <img src={img1} alt="" />
              </div>
              <div
                className={[classes.readyRowColumnTitle, "font-20"].join(" ")}
              >
                Back-end development
              </div>
            </div>
          </div>
          <div className={classes.readyBottom}>
            <div className={classes.readyBottomInfo}>
              <div className={classes.readyBottomInfoBg}></div>
              <div
                className={[classes.readyBottomInfoTitle, "font-38"].join(" ")}
              >
                Turning Vision into Reality 
              </div>
              <div
                className={[classes.readyBottomInfoSubtitle, "font-20"].join(
                  " "
                )}
              >
                Unlock the potential of your business with tailored software solutions. Our expert team is dedicated to creating custom software that aligns perfectly with your unique needs and objectives. Whether you require efficient data management, enhanced customer experiences, or streamlined operations, our software development services are designed to empower your business for success.
              </div>
            </div>
            <div className={classes.readyBottomWraper}>
              <div className={classes.readyBottomEarth}></div>
              <div className={classes.readyBottomPeople}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ready;
