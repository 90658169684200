import React, { useState, useEffect } from "react";
import classes from "./Services.module.scss";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Popup from "../../components/GlobalComponents/Popup/Popup";
import Loader from "../../components/GlobalComponents/Loader/Loader";
import Ready from "../../components/ServicesPage/Ready/Ready";
import Startups from "../../components/ServicesPage/Startups/Startups";
import Process from "../../components/ServicesPage/Process/Process";
import Stars from "../../components/GlobalComponents/Stars/Stars";
import Portfolio from "../../components/Portfolio";
const Services = () => {
  const [playAnimation, setPlayAnimation] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [formName, serFormName] = useState("default form name");
  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 1500);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);
  return (
    <div className={classes.services}>
      <Stars />
      <Loader visible={playAnimation} />
      <div className={classes.headerWrap}>
        <Header
          setPopupVisible={setPopupVisible}
          serFormName={serFormName}
          formName={
            "Services & Our Works Page, Header, Button: Connect With us!"
          }
        />
      </div>

      <Popup
        formName={formName}
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
      />
      <div className={classes.content}>
        <Ready />
        <Portfolio/>
        <Process />
        {/*<Demand
          setPopupVisible={setPopupVisible}
          serFormName={serFormName}
          formName={
            "Services Page, Top NFTs are in High Demand on Today’s Market., Button: Invest in NFT"
          }
          formName1={
            "Services Page, Top NFTs are in High Demand on Today’s Market., Button: Create my Own NFT"
          }
        />*/}
        <Startups />
      </div>
      <Footer second={true} />
    </div>
  );
};

export default Services;
