import React from "react";
import classes from './Mission.module.scss';

const Mission = () => {
    return (
        <div className="container">
            <div className={classes.mission}>
                <div className={classes.title}>
                    Mission and that Influence us to Move Ahead
                </div>
                <div className={classes.content}>
                    <div className={classes.col}>
                        <div className={classes.title}>
                            Mission
                        </div>
                        <div className={classes.text}>
                            To help young entrepreneurs & large enterprises with effective team scalability as well as deliver futuristic software solutions that deliver long- term revenue and business growth.
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.title}>
                            Vision
                        </div>
                        <div className={classes.text}>
                            To transform organizations’ ideologies into full-proof and result-oriented solutions as well as associate with them for a long and productive tenure such that they can shape a better future.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mission;
