import React from "react";
import classes from "./Startups.module.scss";
//import intel from "../../../assets/img/services/3/1.png";
//import nokia from "../../../assets/img/services/3/2.png";
//import unilever from "../../../assets/img/services/3/3.png";
//import nestle from "../../../assets/img/services/3/4.png";
//import samsung from "../../../assets/img/services/3/5.png";
//import toshiba from "../../../assets/img/services/3/6.png";
import work1 from "../../../assets/svg/work1.png";
import work3 from "../../../assets/svg/work3.png";
import work5 from "../../../assets/svg/work5.png";
import work6 from "../../../assets/svg/work6.png";
import work7 from "../../../assets/svg/work7.png";
import work8 from "../../../assets/svg/work8.png";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import { CustomEase } from "gsap/all";

const Startups = () => {
  const ref = useRef(null);
  useEffect(() => {
    const element = ref.current;
    // if (document.documentElement.clientWidth > 1220) {
    gsap.fromTo(
      element.querySelector("#intel"),
      {
        y: 0,
      },
      {
        y: 50,
        yoyo: true,
        duration: 2,
        ease: CustomEase.create(
          "custom",
          "M0,0 C0,0 0.116,0.06 0.176,0.106 0.246,0.16 0.344,0.256 0.388,0.314 0.438,0.38 0.435,0.367 0.468,0.432 0.508,0.51 0.553,0.582 0.598,0.642 0.658,0.722 0.624,0.68 0.674,0.74 0.724,0.8 0.715,0.785 0.77,0.836 0.84,0.9 0.802,0.867 0.862,0.916 0.92,0.964 1,1 1,1 "
        ),
        repeat: -1,
      }
    );
    gsap.fromTo(
      element.querySelector("#nokia"),
      {
        y: 10,
      },
      {
        y: 0,
        yoyo: true,
        duration: 2,
        ease: CustomEase.create(
          "custom",
          "M0,0 C0,0 0.116,0.06 0.176,0.106 0.246,0.16 0.344,0.256 0.388,0.314 0.438,0.38 0.435,0.367 0.468,0.432 0.508,0.51 0.553,0.582 0.598,0.642 0.658,0.722 0.624,0.68 0.674,0.74 0.724,0.8 0.715,0.785 0.77,0.836 0.84,0.9 0.802,0.867 0.862,0.916 0.92,0.964 1,1 1,1 "
        ),
        repeat: -1,
      }
    );
    gsap.fromTo(
      element.querySelector("#unilever"),
      {
        y: -30,
      },
      {
        y: 20,
        yoyo: true,
        duration: 4,
        ease: CustomEase.create(
          "custom",
          "M0,0 C0,0 0.116,0.06 0.176,0.106 0.246,0.16 0.344,0.256 0.388,0.314 0.438,0.38 0.435,0.367 0.468,0.432 0.508,0.51 0.553,0.582 0.598,0.642 0.658,0.722 0.624,0.68 0.674,0.74 0.724,0.8 0.715,0.785 0.77,0.836 0.84,0.9 0.802,0.867 0.862,0.916 0.92,0.964 1,1 1,1 "
        ),
        repeat: -1,
      }
    );
    gsap.fromTo(
      element.querySelector("#nestle"),
      {
        y: 30,
      },
      {
        y: 10,
        yoyo: true,
        duration: 3,
        ease: CustomEase.create(
          "custom",
          "M0,0 C0,0 0.116,0.06 0.176,0.106 0.246,0.16 0.344,0.256 0.388,0.314 0.438,0.38 0.435,0.367 0.468,0.432 0.508,0.51 0.553,0.582 0.598,0.642 0.658,0.722 0.624,0.68 0.674,0.74 0.724,0.8 0.715,0.785 0.77,0.836 0.84,0.9 0.802,0.867 0.862,0.916 0.92,0.964 1,1 1,1 "
        ),
        repeat: -1,
      }
    );
    gsap.fromTo(
      element.querySelector("#samsung"),
      {
        y: -10,
      },
      {
        y: 0,
        yoyo: true,
        duration: 3,
        ease: CustomEase.create(
          "custom",
          "M0,0 C0,0 0.116,0.06 0.176,0.106 0.246,0.16 0.344,0.256 0.388,0.314 0.438,0.38 0.435,0.367 0.468,0.432 0.508,0.51 0.553,0.582 0.598,0.642 0.658,0.722 0.624,0.68 0.674,0.74 0.724,0.8 0.715,0.785 0.77,0.836 0.84,0.9 0.802,0.867 0.862,0.916 0.92,0.964 1,1 1,1 "
        ),
        repeat: -1,
      }
    );
    gsap.fromTo(
      element.querySelector("#toshiba"),
      {
        y: 50,
      },
      {
        y: 0,
        yoyo: true,
        duration: 4,
        ease: CustomEase.create(
          "custom",
          "M0,0 C0,0 0.116,0.06 0.176,0.106 0.246,0.16 0.344,0.256 0.388,0.314 0.438,0.38 0.435,0.367 0.468,0.432 0.508,0.51 0.553,0.582 0.598,0.642 0.658,0.722 0.624,0.68 0.674,0.74 0.724,0.8 0.715,0.785 0.77,0.836 0.84,0.9 0.802,0.867 0.862,0.916 0.92,0.964 1,1 1,1 "
        ),
        repeat: -1,
      }
    );
    // }
  }, []);
  return (
    <div className={classes.startups} ref={ref}>
      <div className={classes.bg}></div>
      <div className={["container", classes.cont].join(" ")}>
        <div className={classes.title}>Who Trusts Us</div>
        <div className={[classes.content, "no-select"].join(" ")}>
          <img
            id="intel"
            className={[classes.icon, classes.intel].join(" ")}
            src={work3}
            alt=""
            width={300}
          />
          <img
            id="nokia"
            className={[classes.icon, classes.nokia].join(" ")}
            src={work5}
            alt=""
            width={300}

          />
          <img
            id="unilever"
            className={[classes.icon, classes.unilever].join(" ")}
            src={work6}
            alt=""
            width={300}
          />
          <img
            id="nestle"
            className={[classes.icon, classes.nestle].join(" ")}
            src={work7}
            alt=""
            width={300}
          />
          <img
            id="samsung"
            className={[classes.icon, classes.samsung].join(" ")}
            src={work8}
            alt=""
            width={300}
          />
          <img
            id="toshiba"
            className={[classes.icon, classes.toshiba].join(" ")}
            src={work1}
            alt=""
            width={300}
          />
        </div>
      </div>
    </div>
  );
};

export default Startups;
