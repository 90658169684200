import React from "react";
import classes from "./Start.module.scss";
import Button from "../../GlobalComponents/Button/Button";

const Start = (props) => {
  return (
    <div className={classes.start}>
      <div className={classes.startBody}>
        <div className="container">
          <div className={[classes.startTitle, "font-38"].join(" ")}>
            Design in 2024
          </div>
          <div className={[classes.startSubtitle, "font-20"].join(" ")}>
            We structure marketing activity by creating emotional positioning,
            unique names and first-class design.
          </div>
          <div className={classes.startBtn}>
            <Button
              onClick={() => {
                props.setPopupVisible(true);
                props.serFormName(props.formName);
              }}
            >
              Get my design
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Start;
