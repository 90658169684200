import React from "react";
import classes from './HowWeCreate.module.scss';
import image from '../../../assets/img/MobileDevelopment/how_we_create.png';

const HowWeCreate = () => {
    return (
        <section className="container">
            <div className={classes.howWeCreate}>
                <h2 className={[classes.title, 'font-38'].join(' ')}>
                    How We Create Mobile App?
                </h2>
                <div className={[classes.text, 'font-20'].join(' ')}>
                    <p>
                    Planning: We start by understanding your app's goals and target audience, creating a solid project plan.</p>
                    <p>
                    Design and Development: Our expert team designs and develops your app, ensuring a seamless user experience.                    </p>
                    <p>
                    Testing and Quality Assurance: Rigorous testing guarantees a flawless app, covering functionality, performance, and security.                    </p>
                    <p>
                    Launch and Support: We assist with app store deployment and offer ongoing support for optimal performance.                    </p>
                   
                </div>
                <img className={[classes.image, 'no-select'].join(' ')} src={image} alt=''/>
            </div>
        </section>
    );
};

export default HowWeCreate;
