import React, { useState } from "react";
import classes from "../../AboutPage/Banner/Banner.module.scss";
import img1 from "../../../assets/img/about/1/2.png";
import Form from "../../GlobalComponents/Form/Form";
const ContactForm = (props) => {
  const [h, setH] = useState(false);
  return (
    <div className={classes.banner}>
      <div className={classes.bannerBody}>
        <div className={["container", classes.bannerWrap].join(" ")}>
          <div className={classes.bannerMoon}>
            <div></div>
          </div>
          <div
            className={
              h
                ? [classes.bannerRobot, classes.hover].join(" ")
                : classes.bannerRobot
            }
          >
            <div></div>
          </div>
          <div className={classes.bannerRow}>
            <div className={classes.bannerRowLeft}>
              <img src={img1} alt="" />
            </div>

            <div className={classes.bannerRowRight}>
              <div className={[classes.bannerTitle, classes.mob].join(" ")}>
                A Glimpse of what Sci-NFT Is Capable of!
              </div>
              <div className={[classes.bannerSubtitle, classes.mob].join(" ")}>
                We are a team of software experts who strive to move beyond your
                tech expectations.
              </div>
              <Form setH={setH} nameForm={props.nameForm} title="Do you have a project? Let's discuss this.<br/> Get a Free Consultation" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;