import React, { useState, useEffect, useRef } from "react";
import gsap, { Power0, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import classes from "./Five.module.scss";
import img from "../../../assets/img/main/5/1.png";
gsap.registerPlugin(ScrollTrigger);

const Five = () => {
  const firstRef = useRef(null);
  const columnRef1 = useRef(null);
  const columnRef2 = useRef(null);
  const columnRef3 = useRef(null);
  const columnRef4 = useRef(null);
  const columnRef5 = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().to(columnRef1.current, {
        top: 0,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top+=2600px",
          end: "center center",
          scrub: 4,
          id: "scrub",
        },
      });
      gsap.timeline().to(columnRef2.current, {
        top: 0,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top+=2600px",
          end: "center center",
          scrub: 4,
          id: "scrub",
        },
      });
      //   gsap.timeline().to(columnRef3.current, {
      //     top: 0,
      //     scrollTrigger: {
      //       trigger: firstRef.current,
      //       start: "top top+=2600px",
      //       end: "center center",
      //       scrub: 4,
      //       id: "scrub",
      //     },
      //   });
      gsap.timeline().to(columnRef4.current, {
        top: 0,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top+=2600px",
          end: "center center",
          scrub: 4,
          id: "scrub",
        },
      });
      gsap.timeline().to(columnRef5.current, {
        top: 0,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top+=2600px",
          end: "center center",
          scrub: 4,
          id: "scrub",
        },
      });
    }
  }, []);
  return (
    <div className={classes.five} ref={firstRef}>
      <div className={classes.fiveBody}>
        <div className="container">
          <div className={[classes.fiveTitle, "title"].join(" ")}>
            Why Do You Need NFT Development?
          </div>
          <div className={classes.secondRow}>
            <div className={classes.secondRowColumn} ref={columnRef1}>
              <div className={classes.secondRowColumnArrows}></div>
              <div className={classes.secondRowColumnItem}>
                <div className={classes.secondRowColumnItemTitle}>$ 300 В</div>
                <div className={classes.secondRowColumnItemText}>
                  The value of NFT Market
                  <br /> Expected by 2030
                </div>
              </div>
            </div>
            <div className={classes.secondRowColumn} ref={columnRef2}>
              <div className={classes.secondRowColumnArrows}></div>
              <div className={classes.secondRowColumnItem}>
                <div className={classes.secondRowColumnItemTitle}>31%</div>
                <div className={classes.secondRowColumnItemText}>
                  Anticipated Growth Rate
                  <br /> between 2022 and 2027
                </div>
              </div>
            </div>
            <div className={classes.secondRowCenter}>
              <div></div>
            </div>
            <div className={classes.secondRowColumn} ref={columnRef4}>
              <div className={classes.secondRowColumnArrows}></div>
              <div className={classes.secondRowColumnItem}>
                <div className={classes.secondRowColumnItemTitle}>$ 38 В</div>
                <div className={classes.secondRowColumnItemText}>
                  Expected sales amount
                  <br /> from NFT in 2022
                </div>
              </div>
            </div>
            <div className={classes.secondRowColumn} ref={columnRef5}>
              <div className={classes.secondRowColumnArrows}></div>
              <div className={classes.secondRowColumnItem}>
                <div className={classes.secondRowColumnItemTitle}>~$2B/mo</div>
                <div className={classes.secondRowColumnItemText}>
                  Total amount of
                  <br /> sales in NFT
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
