import React from "react";
import classes from './Innovation.module.scss';
import image_desc from '../../../assets/img/about/innovation_desc.png';
import image_mob from '../../../assets/img/about/innovation_mob.png';
import gsap from "gsap";
import { useEffect, useRef } from "react";

const Innovation = () => {
    const ref = useRef(null);
    useEffect(() => {
        const element = ref.current;
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(
                element.querySelector('#image_desc'),
                {
                    y: 200,
                    x: -200,
                },
                {
                    y: 0,
                    x: 0,
                    scrollTrigger: {
                        trigger: element.querySelector('#innovation'),
                        start: "top bottom",
                        end: "center center",
                        scrub: 2,
                        id: 'scrub',
                    }
                }
            );
        }
    }, []);
    return (
        <div className={classes.innCont} ref={ref}>
            <div className='container'>
                <div id='innovation' className={classes.innovation}>
                    <div className={classes.title}>
                        If It’s <b style={{color: 'yellow'}}>Innovation</b> approach for development
                    </div>
                    <div className={classes.text}>
                        <div>
                        <b style={{color: 'yellow'}}>Innovation</b> isn't just a buzzword; it's the cornerstone of our development philosophy. We believe that pushing the boundaries of what's possible is the key to creating truly exceptional digital solutions.

                        <div className={classes.par}>
                        At <b style={{color: 'yellow'}}>Innovix</b>, we embrace innovation at every stage of development. From brainstorming fresh ideas to implementing cutting-edge technologies, we're driven to redefine the status quo. Our approach is guided by a relentless pursuit of creative solutions and a commitment to staying ahead of the curve.                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <img id='image_desc' className={[classes.image_desc, 'no-select'].join(' ')} src={image_desc} alt=''/>
            <img className={[classes.image_mob, 'no-select'].join(' ')} src={image_mob} alt=''/>
        </div>
    );
};

export default Innovation;
