import React from "react";
import classes from "./Banner.module.scss"
import Button from "../../GlobalComponents/Button/Button";
import man from "../../../assets/img/services/1/people.png"

const Banner = ({ setPopupVisible, serFormName, formName }) => {
    return (
        <div className={[classes.bannerContainer, 'container'].join(" ")}>
            <div className={classes.bannerTextWrap}>
                <h1 className={classes.bannerTitle}>Web development
                <img className={classes.manBanner} alt="" src={man}/>
                </h1>
                <p className={classes.bannerText}>We develop complex websites and web applications that 
                can handle high traffic volumes and open up new opportunities for your clients.</p>
                <p className={[classes.bannerText, classes.bannerLastText].join(" ")}>Our team does not just write code: we conduct 
                thorough research of business processes and find the best solution for your task, creating a project from scratch or 
                refining an existing one.</p>
                <Button
                    onClick={() => {
                        setPopupVisible(true);
                        serFormName(formName);
                    }}
                >Place an order</Button>
            </div>
        </div>
    )
}

export default Banner;