import React, { useState, useEffect } from "react";
import classes from "./BrandingPage.module.scss";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Popup from "../../components/GlobalComponents/Popup/Popup";
import Loader from "../../components/GlobalComponents/Loader/Loader";
import Banner from "../../components/BrandingPage/Banner/Banner";
import Start from "../../components/BrandingPage/Start/Start";
import Approach from "../../components/BrandingPage/Approach/Approach";
import Six from "../../components/BrandingPage/Six/Six";
import Process from "../../components/BrandingPage/Process/Process";
import Stages from "../../components/BrandingPage/Stages/Stages";
import Stars from "../../components/GlobalComponents/Stars/Stars";
const BrandingPage = () => {
  const [playAnimation, setPlayAnimation] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [formName, serFormName] = useState("default form name");
  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 1500);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);
  return (
    <div className={classes.branding}>
      <Stars />
      <Loader visible={playAnimation} />
      <div className={classes.headerWrap}>
        <Header
          setPopupVisible={setPopupVisible}
          serFormName={serFormName}
          formName={"Branding Page, Header, Button: Connect With us!"}
        />
      </div>

      <Popup
        formName={formName}
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
      />
      <div className={classes.content}>
        {/* <div className={classes.img}>
          <img src={img1} alt="" />
        </div> */}

        <Start
          setPopupVisible={setPopupVisible}
          serFormName={serFormName}
          formName={"Branding Page, First Block, Button: Sign up"}
        />
        <Approach />
        <Six />
        {/*<Process />
        <Stages />
        <Banner
          nameForm={
            "Bottom Form(lets talk about your transformation) Branding Page."
          }
        />*/}
      </div>
      <Footer second={true} />
    </div>
  );
};

export default BrandingPage;
