import React, { useState, useEffect, useRef } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Second.module.scss";
import gsap, { Power0, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Second = (props) => {
  const firstRef = useRef(null);
  const columnRef1 = useRef(null);
  const columnRef2 = useRef(null);
  const columnRef3 = useRef(null);
  const columnRef4 = useRef(null);
  const columnRef5 = useRef(null);

  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().to(columnRef1.current, {
        top: 0,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top+=2600px",
          end: "center center",
          scrub: 4,
          id: "scrub",
        },
      });
      gsap.timeline().to(columnRef2.current, {
        top: 0,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top+=2600px",
          end: "center center",
          scrub: 4,
          id: "scrub",
        },
      });
      gsap.timeline().to(columnRef3.current, {
        top: 0,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top+=2600px",
          end: "center center",
          scrub: 4,
          id: "scrub",
        },
      });
      gsap.timeline().to(columnRef4.current, {
        top: 0,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top+=2600px",
          end: "center center",
          scrub: 4,
          id: "scrub",
        },
      });
      gsap.timeline().to(columnRef5.current, {
        top: 0,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top top+=2600px",
          end: "center center",
          scrub: 4,
          id: "scrub",
        },
      });
    }
  }, []);
  return (
    <div className={classes.second} ref={firstRef}>
      <div className={classes.secondBody}>
        <div className="container">
          <div className={classes.secondText1}>Success Metrics</div>
          <div className={classes.secondRowWrapper}>
            <div className={classes.secondRow}>
              <div className={classes.secondRowColumn} ref={columnRef1}>
                <div className={classes.secondRowColumnArrows}></div>
                <div className={classes.secondRowColumnItem}>
                  <div className={classes.secondRowColumnItemTitle}>10 +</div>
                  <div className={classes.secondRowColumnItemText}>
                    Projects <br /> we elaborated
                  </div>
                </div>
              </div>
              <div className={classes.secondRowColumn} ref={columnRef2}>
                <div className={classes.secondRowColumnArrows}></div>
                <div className={classes.secondRowColumnItem}>
                  <div className={classes.secondRowColumnItemTitle}>5 +</div>
                  <div className={classes.secondRowColumnItemText}>
                    Years of expertise
                    <br /> in different domain
                  </div>
                </div>
              </div>
              <div className={classes.secondRowColumn} ref={columnRef3}>
                <div className={classes.secondRowColumnArrows}></div>
                <div className={classes.secondRowColumnItem}>
                  <div className={classes.secondRowColumnItemTitle}>10+</div>
                  <div className={classes.secondRowColumnItemText}>
                    Developers, designers,
                  </div>
                </div>
              </div>
              <div className={classes.secondRowColumn} ref={columnRef4}>
                <div className={classes.secondRowColumnArrows}></div>
                <div className={classes.secondRowColumnItem}>
                  <div className={classes.secondRowColumnItemTitle}>10 +</div>
                  <div className={classes.secondRowColumnItemText}>
                    Satisfied
                    <br /> clients
                  </div>
                </div>
              </div>
              <div className={classes.secondRowColumn} ref={columnRef5}>
                <div className={classes.secondRowColumnArrows}></div>
                <div className={classes.secondRowColumnItem}>
                  <div className={classes.secondRowColumnItemTitle}>10000 +</div>
                  <div className={classes.secondRowColumnItemText}>
                  Hours were dedicated
                   <br /> for this 
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.secondBtn}>
            <Button
              onClick={() => {
                props.setPopupVisible(true);
                props.serFormName(props.formName);
              }}
            >
              Connect with us!
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
