import React, { useState } from "react";
import classes from "./Six.module.scss";
import img1 from "../../../assets/img/branding/3/1.png";

const Six = () => {
  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: "UI/UX Discovery",
      text: "Uncover potential design opportunities and threats through an in-depth analysis of user needs, market trends, and competitor interfaces. Collaborate closely with the design team to define the user journey and design trends.",
      active: true,
      },
      {
      id: 2,
      title: "Design Strategy",
      text: "Craft a unique design approach that sets your user experience apart from competitors and serves as the foundation for all design decisions over the next 5-10 years.",
      active: false,
      },
      {
      id: 3,
      title: "Brand Identity Creation",
      text: "Establish the visual identity for your brand, including logo design, typography, color schemes, and guidelines for consistent application across digital and physical touchpoints.",
      active: false,
      },
      {
      id: 4,
      title: "Innovative User-Centric Solutions",
      text: "Devise strategies and projects that enhance and modernize user-focused design processes, keeping your interface at the forefront of innovation.",
      active: false,
      },
      {
      id: 5,
      title: "Engagement Strategy",
      text: "Craft compelling user-centered interfaces and communication strategies to elevate brand recognition and drive user engagement and satisfaction.",
      active: false,
      },
  ]);

  return (
    <div className={classes.six}>
      <div className={classes.sixBody}>
        <div className="container">
          <div className={[classes.sixTitle, "title"].join(" ")}>
            Cooperation Flexibility We Deliver
          </div>
          <div className={classes.sixRow}>
            <div className={classes.sixRowLeft}>
              <div className={classes.sixRowLeftTabs}>
                {tabs.map((i) => {
                  return (
                    <div
                      key={i.id}
                      className={
                        i.active
                          ? [classes.sixRowLeftTab, classes.active].join(" ")
                          : classes.sixRowLeftTab
                      }
                    >
                      <div
                        className={classes.sixRowLeftTabTitle}
                        onClick={() => {
                          setTabs(
                            tabs.map((subI) => {
                              if (i.id === subI.id) {
                                return { ...subI, active: true };
                              } else {
                                return { ...subI, active: false };
                              }
                            })
                          );
                        }}
                      >
                        {i.title}
                      </div>
                      <div className={classes.sixRowLeftTabText}>
                        <span> {i.text}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.sixRowRight}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Six;
