import * as Yup from 'yup';

export const validate = async (values) => {
  const errors = {};

  const yupValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Enter a valid email address').required('Email is required'),
    phone: Yup.string().matches(/^\d+$/, 'Only numbers are allowed')
  });

  try {
    await yupValidationSchema.validate(values, { abortEarly: false });
  } catch (yupErrors) {
    yupErrors.inner.forEach((error) => {
      errors[error.path] = error.message;
    });
  }

  return errors;
};
