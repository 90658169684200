import React, { useState, useEffect, useRef } from "react";
import classes from "./Header.module.scss";
import logo from "../../../assets/svg/logo_in.svg";
import arrow from "../../../assets/img/header/arrow.svg";
import { headerRoutes } from "../../../router/index";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import "./Header.scss";
import $ from "jquery";
import Cookies from "js-cookie";

const Header = (props) => {
  const { t } = useTranslation();

  global.text = t;
  const languages = [
    { value: "ru", label: "Ru" },
    { value: "en", label: "En" },
    { value: "es", label: "Es" },
    { value: "it", label: "It" },
    { value: "de", label: "De" },
    { value: "pl", label: "Pl" },
  ];
  const [localA, setLocalA] = useState(false);
  const [burgerA, setBurgerA] = useState(false);

  let location = useLocation();
  const [numNav, setNumNav] = useState(0);
  const [widthFull, setWidthFull] = useState(false);
  const [defaultOption, setDefaultOption] = useState(Cookies.get("i18next"));

  useEffect(() => {
    setDefaultOption(Cookies.get("i18next"));
  }, []);

  useEffect(() => {
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);

  return (
    <div className={classes.header}>
      <div className={classes.headerBody}>
        <div className="container">
          <div className={classes.headerRow}>
            <div
              className={
                burgerA
                  ? [classes.headerRowLogo, classes.active].join(" ")
                  : classes.headerRowLogo
              }
            >
              <Link to="/" style={{"fontSize": 35, "color": "yellow", "fontWeight": 600}}>
                <img src={logo} alt="" width={150}/>
              </Link>
            </div>

            <div className={classes.headerRowRight}>
              <div
                className={
                  burgerA
                    ? [classes.headerRowCenterWrapper, classes.active].join(" ")
                    : classes.headerRowCenterWrapper
                }
              >
                <div
                  className={[
                    classes.headerRowRightAbout,
                    classes.headerDev,
                  ].join(" ")}
                >
                  <div className={classes.block}>
                    <div className={classes.svgwrapper}>
                      <svg
                        height="40"
                        width="150"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          className={classes.shape}
                          height="40"
                          width="150"
                        />
                      </svg>
                    </div>
                    <p to={"/mobile-development"}>Development</p>
                  </div>
                  <div className={classes.headerRowRightAboutPopup}>
                    <div className={classes.headerRowRightAboutPopupContent}>
                      <NavLink className="navItem" to={"/web-development"}>
                        Web development
                      </NavLink>
                      <NavLink to={"/mobile-development"} className="navItem">
                        Mobile development
                      </NavLink>
                      <NavLink to={"/designer"} className="navItem">
                        Design for you
                      </NavLink>
                      {/*<NavLink to={"/digital-marketing"} className="navItem">
                        Digital marketing
                      </NavLink>
                      <NavLink to={"/branding"} className="navItem">
                        Branding
                      </NavLink>*/}
                    </div>
                  </div>
                </div>
                <div
                  className={[
                    classes.headerRowRightAbout,
                    classes.headerServices,
                  ].join(" ")}
                >
                  <div className={classes.block}>
                    <div className={classes.svgwrapper}>
                      <svg
                        height="40"
                        width="200"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          className={classes.shape}
                          height="40"
                          width="200"
                        />
                      </svg>
                    </div>
                    <Link to={"/services"}>Services & Our Works</Link>
                  </div>
                </div>
                <div className={classes.headerRowRightAbout}>
                  <div className={classes.block}>
                    <div className={classes.svgwrapper}>
                      <svg
                        height="40"
                        width="150"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          className={classes.shape}
                          height="40"
                          width="150"
                        />
                      </svg>
                    </div>
                    <Link to={"/about"}>About Us</Link>
                  </div>
                </div>
                <div className={classes.headerRowRightContacts}>
                  <div className={classes.block}>
                    <div className={classes.svgwrapper}>
                      <svg
                        height="40"
                        width="150"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          className={classes.shape}
                          height="40"
                          width="150"
                        />
                      </svg>
                    </div>
                    <Link to={"/contacts"}>Contacts</Link>
                  </div>
                </div>
                <div
                  onClick={() => {
                    props.setPopupVisible(true);
                    props.serFormName(props.formName);
                  }}
                  className={[
                    classes.headerRowRightConnect,
                    classes.tildaripple,
                  ].join(" ")}
                >
                  <div className={classes.ripple_effects}></div>
                  Connect with us!
                </div>
              </div>
              <div
                className={
                  burgerA
                    ? [
                        classes.headerRowCenterWrapper,
                        classes.active,
                        classes.mob,
                      ].join(" ")
                    : [classes.headerRowCenterWrapper, classes.mob].join(" ")
                }
              >
                <div className={classes.headerRowCenterWrapperScrollWrap}>
                  <div className={classes.headerRowCenterWrapperScroll}>
                    <NavLink to={"/mobile-development"} className="navItem">
                      Mobile development
                    </NavLink>
                    <NavLink to={"/web-development"} className="navItem">
                      Web development
                    </NavLink>
                    {/*<NavLink to={"/designer"} className="navItem">
                      Design for you
                    </NavLink>*/}
                    {/*<NavLink to={"/branding"} className="navItem">
                      Branding
                    </NavLink>*/}
                    <NavLink to={"/services"} className="navItem">
                      Services & Our Works
                    </NavLink>
                    <NavLink to={"/about"} className="navItem">
                      About Us
                    </NavLink>
                    <NavLink to={"/contacts"} className="navItem">
                      Contacts
                    </NavLink>
                    <div
                      onClick={() => {
                        props.setPopupVisible(true);
                        props.serFormName(props.formName);
                      }}
                      className={[
                        classes.headerRowRightConnect,
                        classes.tildaripple,
                      ].join(" ")}
                    >
                      <div className={classes.ripple_effects}></div>
                      Connect with us!
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  burgerA
                    ? [classes.headerRowRightBurger, classes.active].join(" ")
                    : classes.headerRowRightBurger
                }
                onClick={() => {
                  if ($("body").hasClass("lock")) {
                    $("body").removeClass("lock");
                  } else {
                    $("body").addClass("lock");
                  }
                  setBurgerA(!burgerA);
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
