import { useEffect, useState } from 'react';
import Banner from '../../components/AboutPage/Banner/Banner';
import Header from '../../components/GlobalComponents/Header/Header';
import classes from './ContactPage.module.scss';
import Loader from '../../components/GlobalComponents/Loader/Loader';
import Popup from '../../components/GlobalComponents/Popup/Popup';
import Stars from '../../components/GlobalComponents/Stars/Stars';
const ContactPage = () => {
  const [playAnimation, setPlayAnimation] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [formName, serFormName] = useState('default form name');
  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 1500);
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);
  return (
    <div className={classes.contact}>
      <Stars />
      <Popup formName={formName} popupVisible={popupVisible} setPopupVisible={setPopupVisible} />
      <Header
        setPopupVisible={setPopupVisible}
        serFormName={serFormName}
        formName={'Contact Page, Header Button: Connect With us!'}
      />
      <Banner nameForm={'A Glimpse of what Sci-NFT Is Capable of! Contact Page.'} />
      <Loader visible={playAnimation} />
    </div>
  );
};

export default ContactPage;
