import React, { useState, useEffect, useRef } from "react";
import classes from "./Four.module.scss";
import gsap, { Power0, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const Four = () => {
  const [numberHov, setNumberHov] = useState(0);
  const [column, setColumn] = useState(0);
  const firstRef = useRef(null);
  const ballRef = useRef(null);

  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().to(ballRef.current, {
        rotate: 360,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top bottom+=200px",
          end: "top top+=200px",
          scrub: 250,
          id: "scrub",
        },
      });
    }
  }, []);

  return (
    <div className={classes.four} ref={firstRef}>
      <div className={classes.fourBody}>
        <div className={classes.fourBigBall} ref={ballRef}>
          <div></div>
        </div>
        <div className="container">
          <div className={[classes.fourTitle, "title"].join(" ")}>
          What our solutions can be for your business
          </div>
          <div className={classes.fourRow}>
            <div
              className={
                column === 1
                  ? [classes.fourRowColumn, classes.click].join(" ")
                  : classes.fourRowColumn
              }
            >
              <div
                className={
                  numberHov === 1
                    ? [classes.fourRowColumnItem1, classes.opacity1].join(" ")
                    : classes.fourRowColumnItem1
                }
                onMouseEnter={() => {
                  if (document.documentElement.clientWidth > 1220) {
                    setNumberHov(2);
                  }
                }}
                onMouseLeave={() => {
                  if (document.documentElement.clientWidth > 1220) {
                    setNumberHov(0);
                  }
                }}
                onClick={() => {
                  if (document.documentElement.clientWidth < 1220) {
                    if (numberHov !== 2) {
                      setNumberHov(2);
                      setColumn(1);
                    } else {
                      setNumberHov(0);
                      setColumn(0);
                    }
                  }
                }}
              >
                <div className={classes.fourRowColumnItem2Wrapper}></div>
                <div className={classes.fourRowColumnItem1Img1}></div>
                <div className={classes.fourRowColumnItem1WrapTitle}>
                  <div className={classes.fourRowColumnItem1Title}>
                    Front-end app
                  </div>
                  <div className={classes.fourRowColumnItem1Subtitle}>
                    <span>
                    Transform Your User Experience with Our Frontend Expertise<br/>

    Our frontend solutions are meticulously crafted to captivate, engage, and convert. We design seamless, responsive, and visually stunning interfaces that leave a lasting impression. Elevate your web presence with our frontend expertise, ensuring your users experience nothing short of excellence.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                column === 2
                  ? [classes.fourRowColumn, classes.click].join(" ")
                  : classes.fourRowColumn
              }
            >
              <div
                className={
                  numberHov === 2
                    ? [classes.fourRowColumnItem2, classes.opacity].join(" ")
                    : classes.fourRowColumnItem2
                }
                onMouseEnter={() => {
                  if (document.documentElement.clientWidth > 1220) {
                    setNumberHov(3);
                  }
                }}
                onMouseLeave={() => {
                  if (document.documentElement.clientWidth > 1220) {
                    setNumberHov(0);
                  }
                }}
                onClick={() => {
                  if (document.documentElement.clientWidth < 1220) {
                    if (numberHov !== 1) {
                      setNumberHov(1);
                      setColumn(2);
                    } else {
                      setNumberHov(0);
                      setColumn(0);
                    }
                  }
                }}
              >
                <div className={classes.fourRowColumnItem2Wrapper}>
                  <div className={classes.fourRowColumnItem2Img1}></div>
                  <div className={classes.fourRowColumnItem2Img2}></div>
                </div>

                <div className={classes.fourRowColumnItem2WrapTitle}>
                  <div className={classes.fourRowColumnItem2Title}>
                    Back-end app
                  </div>
                  <div className={classes.fourRowColumnItem2Subtitle}>
                    <span>
                   Powering Your Business with Robust Backend Solutions<br/>

Behind every great application is a robust backend system. Our backend solutions are engineered for performance, scalability, and security. From database management to server-side logic, we've got the technical prowess to handle it all. 
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                column === 3
                  ? [classes.fourRowColumn, classes.click].join(" ")
                  : classes.fourRowColumn
              }
            >
              <div
                className={
                  numberHov === 3
                    ? [classes.fourRowColumnItem3, classes.opacity].join(" ")
                    : classes.fourRowColumnItem3
                }
                onMouseEnter={() => {
                  if (document.documentElement.clientWidth > 1220) {
                    setNumberHov(4);
                  }
                }}
                onMouseLeave={() => {
                  if (document.documentElement.clientWidth > 1220) {
                    setNumberHov(0);
                  }
                }}
                onClick={() => {
                  if (document.documentElement.clientWidth < 1220) {
                    if (numberHov !== 4) {
                      setNumberHov(4);
                      setColumn(3);
                    } else {
                      setNumberHov(0);
                      setColumn(0);
                    }
                  }
                }}
              >
                <div className={classes.fourRowColumnItem2Wrapper}></div>
                <div className={classes.fourRowColumnItem3Img1}></div>
                <div className={classes.fourRowColumnItem3WrapTitle}>
                  <div className={classes.fourRowColumnItem3Title}>
                    Design solutions
                  </div>
                  <div className={classes.fourRowColumnItem3Subtitle}>
                    <span>
                    Design solutions in the context of a software development 
                    company refer to the process of creating user-centric and visually 
                    appealing interfaces and experiences for digital products and applications. 
                    These solutions play a crucial role in helping businesses achieve their goals and 
                    objectives by enhancing the overall user experience and maximizing the impact of their 
                    software solutions.   </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                column === 4
                  ? [classes.fourRowColumn, classes.click].join(" ")
                  : classes.fourRowColumn
              }
            >
              <div
                className={
                  numberHov === 4
                    ? [classes.fourRowColumnItem4, classes.opacity].join(" ")
                    : classes.fourRowColumnItem4
                }
                onMouseEnter={() => {
                  if (document.documentElement.clientWidth > 1220) {
                    setNumberHov(3);
                  }
                }}
                onMouseLeave={() => {
                  if (document.documentElement.clientWidth > 1220) {
                    setNumberHov(0);
                  }
                }}
                onClick={() => {
                  if (document.documentElement.clientWidth < 1220) {
                    if (numberHov !== 3) {
                      setNumberHov(3);
                      setColumn(4);
                    } else {
                      setNumberHov(0);
                      setColumn(0);
                    }
                  }
                }}
              >
                <div className={classes.fourRowColumnItem2Wrapper}>
                  <div className={classes.fourRowColumnItem4Img1}></div>
                  <div className={classes.fourRowColumnItem4Img2}></div>
                </div>

                <div className={classes.fourRowColumnItem4Img3}></div>
                <div className={classes.fourRowColumnItem4WrapTitle}>
                  <div className={classes.fourRowColumnItem4Title}>
                    Mobile solutions
                  </div>
                  <div className={classes.fourRowColumnItem4Subtitle}>
                    <span>
                    Cross-platform mobile development refers to the practice of building
                     mobile applications that can run on multiple operating systems and devices 
                     using a single codebase. This approach to development offers several advantages for 
                     businesses, primarily focused on efficiency, cost savings, and broad market reach. </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
