import React from "react";
import { Puff } from "react-loader-spinner";
import classes from "./Loader.module.scss";
const Loader = (props) => {
  return (
    <div
      className={
        props.visible
          ? classes.loader
          : [classes.loader, classes.hide].join(" ")
      }
    >
      <Puff
        height="80"
        width="80"
        radius={1}
        color="#7ECCFF"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default Loader;
