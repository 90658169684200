import { useEffect, useState } from "react";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Form from "../../components/GlobalComponents/Form/Form";
import Header from "../../components/GlobalComponents/Header/Header";
import Eight from "../../components/MainPage/Eight/Eight";
import First from "../../components/MainPage/First/First";
import Five from "../../components/MainPage/Five/Five";
import Four from "../../components/MainPage/Four/Four";
import Nine from "../../components/MainPage/Nine/Nine";
import Second from "../../components/MainPage/Second/Second";
import Seven from "../../components/MainPage/Seven/Seven";
import Six from "../../components/MainPage/Six/Six";
import Three from "../../components/MainPage/Three/Three";
import classes from "./MainPage.module.scss";
import Loader from "../../components/GlobalComponents/Loader/Loader";
import Popup from "../../components/GlobalComponents/Popup/Popup";
import Stars from "../../components/GlobalComponents/Stars/Stars";
const MainPage = () => {
  const [playAnimation, setPlayAnimation] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [formName, serFormName] = useState("default form name");
  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 1500);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <div className={classes.main}>
      <Stars />
      <Loader visible={playAnimation} />
      <div className={classes.headerWrap}>
        <Header
          setPopupVisible={setPopupVisible}
          serFormName={serFormName}
          formName={"Main Page, Header, Button: Connect With us!"}
        />
      </div>
      <Popup
        formName={formName}
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
      />
      <First
        setPopupVisible={setPopupVisible}
        serFormName={serFormName}
        formName={"Main Page, First Block, Button: Connect With us!"}
      />
      <div className={classes.mainWrap}>
  
        <Four />
          <Second
          setPopupVisible={setPopupVisible}
          serFormName={serFormName}
          formName={"Main Page, Second Block, Button: Connect With us!"}
        />
        <Three />
        {/*<Five />
        <Six />
        <Seven />
        <Eight />*/}
        <Form
          smartForm={false}
          nameForm={
            "Are you interested in getting financial profit via NFT development? Main Page."
          }
        />
        {/*<Nine />*/}
        <Footer />
      </div>
    </div>
  );
};

export default MainPage;
