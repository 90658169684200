import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/svg/logo_in.svg";
import { Link } from "react-router-dom";
const Footer = (props) => {
  return (
    <div className={classes.footer}>
      <div
        className={
          props.second
            ? [classes.footerBody, classes.second].join(" ")
            : classes.footerBody
        }
      >
        <div className="container">
          <div className={classes.footerTop}>
            <Link to={"/"} className={classes.footerTopLogo}>
              <img src={logo} alt="" width={200}/>
            </Link>
          </div>
          <div className={classes.footerText}>
            <span>
            Web development and graphic design company that offers<br/> cutting edge web technologies and innovative design services.
              
            </span>
            <span>innovix@gmial.com</span>
          </div>
          <div className={classes.footerText}>
            <span>© 2023 All Rights Reserved</span>
            <span>
              Locations: <br/> Kyiv, Lva Tolstogo 15, Poland, Wroclav, Komuny Paryskiej 19 <br />
              Innovix: +38 09739 20998
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
