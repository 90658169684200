import React from "react";
import classes from "./Process.module.scss";

const Process = () => {
  return (
    <div className={classes.process}>
      <div className={classes.processBody}>
        <div className="container">
          <div className={[classes.processTitle, "font-38"].join(" ")}>
            Our Process Issues of Developing
          </div>
          <div className={classes.processRow}>
            <div className={classes.processRowLeft}>
              <div
                className={[classes.processRowLeftTitle, "font-20"].join(" ")}
              >
                Excellent technical communication
              </div>
              <div
                className={[classes.processRowLeftSubtitle, "font-20"].join(
                  " "
                )}
              >
                Our developers possess strong written and verbal communication
                skills. They work effectively across multiple collaboration
                tools and convey complex engineering ideas and concepts with
                ease.
              </div>
              <div
                className={[classes.processRowLeftTitle, "font-20"].join(" ")}
              >
                Core skills and algorithms
              </div>
              <div
                className={[classes.processRowLeftSubtitle, "font-20"].join(
                  " "
                )}
              >
                Each developer demonstrates its computer science fundamentals,
                problem-solving ability, and technical aptitude to a panel of
                leading experts.
              </div>
              <div
                className={[classes.processRowLeftTitle, "font-20"].join(" ")}
              >
                Proactive problem-solving
              </div>
              <div
                className={[classes.processRowLeftSubtitle, "font-20"].join(
                  " "
                )}
              >
                Our developers are skilled at tackling roadblocks creatively and
                independently. Each candidate is live-screened by two top
                developers where they have to offer multiple paths to the
                solution and make decisions on the spot.
              </div>
            </div>
            <div className={classes.processRowRight}>
              <div
                className={[classes.processRowRightTitle, "font-20"].join(" ")}
              >
                End-to-End project execution
              </div>
              <div
                className={[classes.processRowRightSubtitle, "font-20"].join(
                  " "
                )}
              >
                Our developers deliver a test project to completion,
                demonstrating their skills across ideating, scoping,
                implementation, and problem-solving.
              </div>
              <div
                className={[classes.processRowRightTitle, "font-20"].join(" ")}
              >
                Continued excellence
              </div>
              <div
                className={[classes.processRowRightSubtitle, "font-20"].join(
                  " "
                )}
              >
                Our developers are expected to maintain a perfect track record
                while working with clients. We assess our talent after every
                engagement to ensure our standards for excellence are met.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
