import { useEffect, useState } from "react";
import Banner from "../../components/WebDevelopment/Banner/Banner";
import OurTechnology from "../../components/WebDevelopment/OurTechnology/OurTechnology";
import EdtechFintechList from "../../components/WebDevelopment/EdtechFintechList/EdtechFintechList";
import WhoTrustsUs from "../../components/WebDevelopment/WhoTrustsUs/WhoTrustsUs";
import WhatIsWebDevelopment from "../../components/WebDevelopment/WhatIsWebDevelopment/WhatIsWebDevelopment";
import OurApproach from "../../components/WebDevelopment/OurApproach/OurApproach";
import FAQ from "../../components/WebDevelopment/FAQ/FAQ";
import ContactForm from "../../components/WebDevelopment/ContactForm/ContactForm";
import Header from "../../components/GlobalComponents/Header/Header";
import Loader from "../../components/GlobalComponents/Loader/Loader";
import Popup from "../../components/GlobalComponents/Popup/Popup";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import classes from "./WebDevelopment.module.scss";
import Stars from "../../components/GlobalComponents/Stars/Stars";
import Startups from "../../components/ServicesPage/Startups/Startups";
import Portfolio from "../../components/Portfolio";

const WebDevelopment = () => {
  const [playAnimation, setPlayAnimation] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [formName, serFormName] = useState("default form name");
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 1500);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);
  return (
    <div className={classes.webDevelopment}>
      <Stars />
      <Popup
        formName={formName}
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
      />
      <Loader visible={playAnimation} />
      <div className={classes.bg}></div>
      <div className={classes.milky}></div>
      <Header
        setPopupVisible={setPopupVisible}
        serFormName={serFormName}
        formName={"Web Development, Header Button: Connect With us!"}
      />
      <Banner
        setPopupVisible={setPopupVisible}
        serFormName={serFormName}
        formName={"Web Development, Header, Button: Place an order"}
      />
      <OurTechnology />
      <EdtechFintechList />
      <Portfolio/>
      {/*<Startups />*/}
      <WhatIsWebDevelopment />
      <OurApproach />
      {/*<FAQ />*/}
      <div className={classes.form}>
        <ContactForm
          smartForm={false}
          nameForm={
            "Do you have a project? Let's discuss this. Get a Free Consultation"
          }
        />
      </div>
      <Footer second={true} />
    </div>
  );
};

export default WebDevelopment;
