import React, { useState, useEffect, useRef } from "react";
import img1 from "../../../assets/img/main/7/1.png";
import img2 from "../../../assets/img/main/7/2.png";
import img3 from "../../../assets/img/main/7/3.png";
import img4 from "../../../assets/img/main/7/4.png";
import img5 from "../../../assets/img/main/7/5.png";
import mob from "../../../assets/img/main/7/mob.png";
import classes from "./Seven.module.scss";
import gsap, { Power0, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Seven = () => {
  const firstRef = useRef(null);
  const ballRef = useRef(null);
  const robotRef = useRef(null);

  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().to(ballRef.current, {
        rotate: 360,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top bottom+=200px",
          end: "top top+=200px",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(robotRef.current, {
        scale: 1,
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top bottom+=200px",
          end: "top top+=200px",
          scrub: 5,
          id: "scrub",
        },
      });
    }
  }, []);

  return (
    <div className={classes.seven} ref={firstRef}>
      <div className={classes.sevenBody}>
        <div className="container">
          <div className={[classes.sevenTitle, "title"].join(" ")}>
            NFT Development Services by Sci-NFT
          </div>
          <div className={classes.sevenRow}>
            <div className={classes.sevenRowCircleImg}>
              <div ref={ballRef}></div>
            </div>
            <div className={classes.sevenRowPeopleImg}>
              <div ref={robotRef}></div>
            </div>
            <div className={classes.sevenRowColumn}>
              <div className={classes.sevenRowColumnImg}>
                <img src={img1} alt="" />
              </div>
              <div className={classes.sevenRowColumnText}>
                NFT marketplace development
              </div>
            </div>
            <div className={classes.sevenRowColumn}>
              <div className={classes.sevenRowColumnImg}>
                <img src={img2} alt="" />
              </div>
              <div className={classes.sevenRowColumnText}>
                Enterprise NFT platform development
              </div>
            </div>
            <div className={classes.sevenRowColumn}>
              <div className={classes.sevenRowColumnImg}>
                <img src={img3} alt="" />
              </div>
              <div className={classes.sevenRowColumnText}>
                NFT minting mechanism development and integration
              </div>
            </div>
            <div className={classes.sevenRowColumn}>
              <div className={classes.sevenRowColumnImg}>
                <img src={img4} alt="" />
              </div>
              <div className={classes.sevenRowColumnText}>
                NFT smart contract development
              </div>
            </div>
            <div className={classes.sevenRowColumn}>
              <div className={classes.sevenRowColumnImg}>
                <img src={img5} alt="" />
              </div>
              <div className={classes.sevenRowColumnText}>
                NFT ecosystem testing
              </div>
            </div>
          </div>
          <div className={classes.sevenMobImg}>
            <img src={mob} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Seven;
