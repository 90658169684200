import React from "react";
import classes from './Portfolio.module.scss';
import image_01 from '../../assets/img/portfolio/image_01.png';
import image_02 from '../../assets/img/portfolio/image_2.png';
import image_03 from '../../assets/img/portfolio/image_3.png';
import image_04 from '../../assets/img/portfolio/image_4.png';
import image_05 from '../../assets/img/portfolio/image_5.png';

import arrow from '../../assets/img/DigitalMarketing/WeWillHelp/arrow.gif';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import { useRef } from "react";
import { Trans } from "react-i18next";
import { useEffect } from "react";

const cards = [
    {
        id: 1,
        image: image_01,
        text: 'Remm<br/>',
    },
    {
        id: 2,
        image: image_02,
        text: 'TBC<br/>Wealth management',
    },
    {
        id: 3,
        image: image_03,
        text: 'PRC<br/>Pandemic Relief Supply',
    },
    {
        id: 4,
        image: image_04,
        text: 'Meta Wealth',
    },
    {
        id: 5,
        image: image_05,
        text: 'BasysBank',
    },
	{
        id: 6,
        image: image_01,
        text: 'Remm<br/>',
    },
    {
        id: 7,
        image: image_02,
        text: 'TBC<br/>Wealth management',
    },
    {
        id: 8,
        image: image_03,
        text: 'PRC<br/>Pandemic Relief Supply',
    },
    {
        id: 9,
        image: image_04,
        text: 'Meta Wealth',
    },
    {
        id: 10,
        image: image_05,
        text: 'BasysBank',
    },
	{
        id: 11,
        image: image_01,
        text: 'Remm<br/>',
    },
    {
        id: 12,
        image: image_02,
        text: 'TBC<br/>Wealth management',
    },
    {
        id: 13,
        image: image_03,
        text: 'PRC<br/>Pandemic Relief Supply',
    },
    {
        id: 14,
        image: image_04,
        text: 'Meta Wealth',
    },
    {
        id: 15,
        image: image_05,
        text: 'BasysBank',
    },
];

const Portfolio = () => {
    const [ current, setCurrent ] = useState(0);
    const slider = useRef(null);
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        afterChange: (current) => setCurrent(current),
    };
    useEffect(() => {
        console.log(current)
    }, [current]);
    return (
        <section className="container">
            <div className={classes.weWillHelp}>
                <h2 className={[classes.title, 'font-38'].join(' ')}>
                    Portfolio
                </h2>
				{/*<h4 className={[classes.title, 'font-25'].join(' ')}>
                    Our design our developers our inspiriting
                </h4>*/}
                <div className={classes.content}>
                    <Slider ref={slider} {...settings}>
                        {cards.map((card) =>
                            <div 
                                className={`${classes.card} ${((current === card.id - 2) || (current === 14 && card.id === 1))&& classes.cardActive}`} 
                                key={card.id}
                            >
                                <img className={[classes.cardImage, 'no-select'].join(' ')} src={card.image} alt=''/>
                                <p className={classes.cardText}>
                                    <Trans>{card.text}</Trans>
                                </p>
                            </div>
                        )}
                    </Slider>
                    <img
                        onClick={() => slider.current.slickPrev()} 
                        className={[classes.arrow, classes.arrowLeft, 'no-select'].join(' ')} 
                        src={arrow} 
                        alt=''
                    />
                    <img 
                        onClick={() => slider.current.slickNext()}
                        className={[classes.arrow, classes.arrowRight, 'no-select'].join(' ')} 
                        src={arrow} 
                        alt=''
                    />
                </div>
            </div>
        </section>
    );
};

export default Portfolio;
