import React from "react";
import classes from "./Button.module.scss";

const Button = (props) => {
  return (
    <div className={classes.btnWrap} {...props}>
      <div className={[classes.btn, classes.tildaripple].join(" ")}>
        <div className={classes.ripple_effects}></div>
        {props.children}
      </div>
    </div>
  );
};

export default Button;
