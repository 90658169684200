import React from "react";
import classes from "./OurTechnology.module.scss"

const OurTechnology = () => {
    const technologiesList = [
        [    
            {item: 'Development with TypeScript'},    
        {item: 'Development with React'},    
        {item: 'NextJs development'}, 
        {item: 'Angular development'},    
        {item: 'Node.js development'},    
        {item: 'Express development'},    
        ],

        [
        {item: 'Nest.js development'},    
            {item: 'React Native development'},    
            {item: 'Flutter development'},    
            {item: 'Custom Software Development'},    
            {item: 'Web Application Development'},    
            {item: 'Mobile App Development'}
        ],
        [
            {item: 'Progressive Web Apps (PWAs)'},    
            {item: 'API Development and Integration'},   
             {item: 'UI/UX Design'},    
             {item: 'Performance Optimization'},    
             {item: 'Security Implementation'}, 
        ],
        [
            {item: 'Cloud Services Integration'},    
         {item: 'Expert Guidance'},    
         {item: 'Project Planning and Strategy'},   
          {item: 'Continuous Maintenance and Updates'},    
         {item: 'Scalability Solutions'}
        ]
      ];
    return (
        <div className={['container', classes.ourTechnologyContainer].join(" ")}>
            <h3 className={classes.ourTechnologyTitle}>Our Technologies and Services</h3>
            <p className={classes.ourTechnologyText}>We help businesses grow</p>
            <div className={classes.ourTechnologyOverflow}>
                <div className={classes.ourTechnologyWrap}>
                    {technologiesList.map((technology, index) => {
                        return (
                            <ul className={classes.ourTechnologyList} key={index}> 
                            { 
                                technology.map(i => <li className={classes.ourTechnologyItem}>{i.item}</li>) 
                            }
                            </ul>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default OurTechnology;