import React from "react";
import Form from "../Form/Form";
import classes from "./Popup.module.scss";

const Popup = (props) => {
  return (
    <div
      className={
        props.popupVisible
          ? [classes.popup, classes.active].join(" ")
          : classes.popup
      }
    >
      <div className={classes.popupWrap}>
        <div
          className={classes.popupClose}
          onClick={() => {
            props.setPopupVisible(false);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Form nameForm={props.formName} backInput={true} />
      </div>
    </div>
  );
};

export default Popup;
