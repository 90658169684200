import { useEffect, useState } from "react";
import classes from "./AboutPage.module.scss";
import Header from "../../components/GlobalComponents/Header/Header";
import Form from "../../components/GlobalComponents/Form/Form";
import Mission from "../../components/AboutPage/Mission/Mission";
import Startups from "../../components/AboutPage/Startups/Startups";
import Success from "../../components/AboutPage/Success/Success";
import Innovation from "../../components/AboutPage/Innovation/Innovation";
import Banner from "../../components/AboutPage/Banner/Banner";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Second from "../../components/AboutPage/Second/Second";
import Loader from "../../components/GlobalComponents/Loader/Loader";
import Popup from "../../components/GlobalComponents/Popup/Popup";
import Stars from "../../components/GlobalComponents/Stars/Stars";

const AboutPage = () => {
  const [playAnimation, setPlayAnimation] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [formName, serFormName] = useState("default form name");
  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 1500);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <div className={classes.aboutPage}>
      <Stars />
      <Popup
        formName={formName}
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
      />
      <Loader visible={playAnimation} />
      <div className={classes.bg}></div>
      <div className={classes.milky}></div>
      <Header
        setPopupVisible={setPopupVisible}
        serFormName={serFormName}
        formName={"About Page, Header Button: Connect With us!"}
      />
      <Banner
        nameForm={"A Glimpse of what Sci-NFT Is Capable of! About Page."}
      />
      <Innovation />

      <Second
        setPopupVisible={setPopupVisible}
        serFormName={serFormName}
        formName={"About Page, Success Metrics Block, Button: Connect With us!"}
      />
      {/* <Success /> */}
      <Mission />
      {/*<Startups />*/}
      {/*<div className={classes.form}>
        <Form
          smartForm={false}
          nameForm={
            "Are you interested in getting financial profit via NFT development? About Page."
          }
        />
      </div>*/}
      <Footer second={true} />
    </div>
  );
};

export default AboutPage;
