import React from "react";
import classes from './OurProcess.module.scss';
import icon_01 from '../../../assets/img/MobileDevelopment/OurProcess/icon_01.png';
import icon_02 from '../../../assets/img/MobileDevelopment/OurProcess/icon_02.png';
import icon_03 from '../../../assets/img/MobileDevelopment/OurProcess/icon_03.png';
import icon_04 from '../../../assets/img/MobileDevelopment/OurProcess/icon_04.png';
import { Trans, useTranslation } from "react-i18next";

const cards = [
    {
        id: 1,
        icon: icon_01,
        text: 'React-Native',
    },
    {
        id: 2,
        icon: icon_02,
        text: 'Flutter/Dart',
    },
    {
        id: 3,
        icon: icon_03,
        text: 'Firebase',
    },
    {
        id: 4,
        icon: icon_04,
        text: 'NodeJs',
    },
];

const OurProcess = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.ourProcess}>
                <div className={[classes.title, 'font-38'].join(' ')}>
                Tools & Frameworks we are using                </div>
                <div className={classes.cards}>
                    {cards.map((card) =>
                        <div className={classes.card} key={card.id}>
                            <img className="no-select" src={card.icon} alt=''/>
                            <div className={[classes.text, 'font-20'].join(' ')}>
                                <Trans>{t(card.text)}</Trans>
                            </div>
                        </div>                    
                    )}
                </div>
            </div>
        </div>
    );
};

export default OurProcess;
