import React, { useEffect, useState } from "react";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from "../../components/MobileDevelopment/Banner/Banner";
import HowWeCreate from "../../components/MobileDevelopment/HowWeCreate/HowWeCreate";
import OurProcess from "../../components/MobileDevelopment/OurProcess/OurProcess";
import Understand from "../../components/MobileDevelopment/Understand/Understand";
import Demand from "../../components/ServicesPage/Demand/Demand";
import classes from "./MobileDevelopment.module.scss";
import demand_planet from "../../assets/img/MobileDevelopment/demand_planet.png";
import OurTop from "../../components/MobileDevelopment/OurTop/OurTop";
import Loader from "../../components/GlobalComponents/Loader/Loader";
import Popup from "../../components/GlobalComponents/Popup/Popup";
import Stars from "../../components/GlobalComponents/Stars/Stars";

const MobileDevelopment = () => {
  const [playAnimation, setPlayAnimation] = useState(true);
  const [popupVisible, setPopupVisible] = useState(false);
  const [formName, serFormName] = useState("default form name");
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 1500);
    };
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);
  return (
    <div className={classes.page}>
      <Stars />
      <Loader visible={playAnimation} />
      <Popup
        formName={formName}
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
      />

      <Banner
        setPopupVisible={setPopupVisible}
        serFormName={serFormName}
        formName={"Nft Development, Header, Button: Place an order"}
      />
      <OurProcess />
      <HowWeCreate />
      {/*<Understand />*/}
      <OurTop />
      <div className={classes.demandBlock}>
        {/*<Demand
          setPopupVisible={setPopupVisible}
          serFormName={serFormName}
          formName={
            "Nft development, Top NFTs are in High Demand on Today’s Market., Button: Invest in NFT"
          }
          formName1={
            "Nft development, Top NFTs are in High Demand on Today’s Market., Button: Create my Own NFT"
          }
        />*/}
        <img
          className={[classes.demandPlanet, "no-select"].join(" ")}
          src={demand_planet}
          alt=""
        />
      </div>
      <Footer second />
    </div>
  );
};

export default MobileDevelopment;
