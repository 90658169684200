import React, { useState } from "react";
import classes from "./EdtechFintechList.module.scss"
import card1 from "../../../assets/img/webDevelopment/card-1.png"
import cardTablet1 from "../../../assets/img/webDevelopment/card-tablet-1.png"
import card2 from "../../../assets/img/webDevelopment/card-2.png"
import cardTablet2 from "../../../assets/img/webDevelopment/card-tablet-2.png"
import card3 from "../../../assets/img/webDevelopment/card-3.png"
import cardTablet3 from "../../../assets/img/webDevelopment/card-tablet-3.png"
import card4 from "../../../assets/img/webDevelopment/card-4.png"
import cardTablet4 from "../../../assets/img/webDevelopment/card-tablet-4.png"

const OurTechnology = () => {
    const [hov1, setHov1] = useState(false);
    const [hov2, setHov2] = useState(false);
    const [hov3, setHov3] = useState(false);
    const [hov4, setHov4] = useState(false);
    return (
        <div className={[classes.edtechFintechContainer, 'container'].join(" ")}>
            <h3 className={classes.edtechFintechTitle}>Industries</h3>
            <h6 className={classes.edtechFintechSubTitle}>We are focused on:</h6>
            <div className={classes.edtechFintechRow}>
                <div className={classes.edtechFintechTextWrap}>
                    <div 
                        onMouseEnter={() => {
                            setHov1(true);
                        }}
                        onMouseLeave={() => {
                            setHov1(false);
                        }}
                    className={classes.edtechFintechText}>Edtech/Fintech</div>
                    <div 
                        onMouseEnter={() => {
                            setHov2(true);
                        }}
                        onMouseLeave={() => {
                            setHov2(false);
                        }}
                        className={classes.edtechFintechText}>CRM</div>
                    {/*<div 
                        onMouseEnter={() => {
                            setHov3(true);
                        }}
                        onMouseLeave={() => {
                            setHov3(false);
                        }}
                        className={classes.edtechFintechText}>Video streaming</div>*/}
                    <div 
                        onMouseEnter={() => {
                            setHov4(true);
                        }}
                        onMouseLeave={() => {
                            setHov4(false);
                        }}
                        className={classes.edtechFintechText}>Startups</div>
                </div>
                <div className={classes.edtechFintechImgWrap}>
                    <div className={
                            hov1
                            ? [classes.edtechFintechImgItem, classes.hover1].join(" ")
                            : classes.edtechFintechImgItem
                    }>
                        <img src={card1} className={[classes.imgFluid, classes.dXlBlock].join(" ")} alt="We launch and develop educational products, create online learning platforms and LMS." />
                        <img src={cardTablet1} className={[classes.imgFluid, classes.dXlNone].join(" ")} alt="We launch and develop educational products, create online learning platforms and LMS." />
                    </div>
                    <div className={
                            hov2
                            ? [classes.edtechFintechImgItem, classes.hover2].join(" ")
                            : classes.edtechFintechImgItem
                    }>
                        <img src={card2} className={[classes.imgFluid, classes.dXlBlock].join(" ")} alt="We specialize in the development of individual, scalable CRM systems adapted to the tasks of your business." />
                        <img src={cardTablet2} className={[classes.imgFluid, classes.dXlNone].join(" ")} alt="We launch and develop educational products, create online learning platforms and LMS." />
                    </div>
                    {/*<div  className={
                            hov3
                            ? [classes.edtechFintechImgItem, classes.hover1].join(" ")
                            : classes.edtechFintechImgItem
                    }>
                        <img src={card3} className={[classes.imgFluid, classes.dXlBlock].join(" ")} alt="We develop streaming services and online cinemas with a reliable backend and content protection." />
                        <img src={cardTablet3} className={[classes.imgFluid, classes.dXlNone].join(" ")} alt="We launch and develop educational products, create online learning platforms and LMS." />
                    </div>*/}
                    <div  className={
                            hov4
                            ? [classes.edtechFintechImgItem, classes.hover2].join(" ")
                            : classes.edtechFintechImgItem
                    }>
                        <img src={card4} className={[classes.imgFluid, classes.dXlBlock].join(" ")} alt="We help startups turn their business ideas into reality." />
                        <img src={cardTablet4} className={[classes.imgFluid, classes.dXlNone].join(" ")} alt="We launch and develop educational products, create online learning platforms and LMS." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurTechnology;