import React from "react";
import classes from "./Approach.module.scss";
import img1 from "../../../assets/img/branding/2/1.png";
import img2 from "../../../assets/img/branding/2/2.png";
import img3 from "../../../assets/img/branding/2/3.png";

const Approach = () => {
  return (
    <div className={classes.approach}>
      <div className={classes.approachBody}>
        <div className="container">
          <div className={[classes.approachTitle, "font-38"].join(" ")}>
            Our Approach
          </div>
          <div className={classes.approachRow}>
            <div className={classes.approachRowColumn}>
              <div className={classes.approachRowColumnImg}>
                <img src={img1} alt="" />
              </div>
              <div
                className={[classes.approachRowColumnText, "font-20"].join(" ")}
              >
                We study the specifics of your business to find a competitive
                design  scenario
              </div>
            </div>
            <div className={classes.approachRowColumn}>
              <div className={classes.approachRowColumnImg}>
                <img src={img2} alt="" />
              </div>
              <div
                className={[classes.approachRowColumnText, "font-20"].join(" ")}
              >
               We coordinate the design with you and finalize it according to your wishes
              </div>
            </div>
            <div className={classes.approachRowColumn}>
              <div className={classes.approachRowColumnImg}>
                <img src={img3} alt="" />
              </div>
              <div
                className={[classes.approachRowColumnText, "font-20"].join(" ")}
              >
               We take your positive feedback
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approach;
